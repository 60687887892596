import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'

import { DefaultLayout } from '../layouts/defaultlayout'
import { Container, FlexBox, FlexChild, MapsIFrame } from '../components'

import { Navigation } from './_navigation'

export const query = graphql`
  query {
    logo: directus {
      ...LogoFragment
    }
    adress: directus {
      ...AdressFragment
    }
  }
`

// eslint-disable-next-line import/no-default-export
export default ({ data }) => (
  <DefaultLayout>
    <Navigation logo={data.logo.logo.logo.imageUrl} />
    <Container>
      <Helmet>
        <meta name="description" content="Datenschutzerklärung" />
      </Helmet>
      <h1>{data.adress.adress.title}</h1>
      <FlexBox>
        <FlexChild col={12} colXl={6}>
          <ReactMarkdown source={data.adress.adress.text} />
        </FlexChild>
        <FlexChild grow col={12} colXl={6}>
          <MapsIFrame
            link={data.adress.adress.adress}
            title={data.adress.adress.title}
          />
        </FlexChild>
      </FlexBox>
    </Container>
  </DefaultLayout>
)
